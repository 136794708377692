import React, {useCallback, useEffect, useState} from 'react'
import "./Invites.scss"

import Footer from "../../components/Footer/Footer";

import 'alpinejs';
import {useLocation, useParams} from "react-router-dom";

const Invites = (props) => {
    const { invitationCode } = useParams();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const invitationURL = queryParams.get('invitationUrl');

    const invitation = invitationCode || invitationURL;

    useEffect(() => {

    }, []);

    return (
        <div className='invites'>
            {/*<Loading/>*/}
            <div className={""}>
                <div className={"card text-center flex flex-col justify-center items-center p-10"}>
                    <p className={"font-rexton text-md pt-4 pb-0  uppercase text-white "}>RESERVE YOUR SPOT</p>
                    <div className={"mt-5 flex flex-col justify-center items-center text-center md:max-w-[700px]"}>
                    <p className={"font-rexton text-sm pb-1 pb-0 mb-2 uppercase text-white "}>USING THE LIKA APP</p>
                        <p className={"font-rexton text-xsm pb-4 pb-0 mb-5 uppercase text-white "}>by downloading the app, you'll gain access to the plan's chat, photo gallery, and refund request features.</p>
                    <div className={"flex flex-col"}>
                        <div>
                            <div className={"download-button"}><a target={"_blank"} href={process.env.REACT_APP_ANDROID_URL}><img src={"img/google_download.png"} className={"w-100"}/></a></div>
                            <div className={"download-button mt-3"}><a target={"_blank"} href={process.env.REACT_APP_IOS_URL}><img src={"img/apple_download.png"} className={"w-100"}/></a></div>
                        </div>
                        <div></div>
                    </div>
                    </div>
                    <div className={"mt-5 flex flex-col justify-center items-center text-center "}>
                        <div className={"flex flex-row items-center mb-5"}>
                            <div className={"seperator flex-1"}></div>
                            <p className={"font-rexton text-sm pb-0 pe-3 ps-3 uppercase text-white "}>OR</p>
                            <div className={"seperator flex-1"}></div>
                        </div>
                    <p className={"font-rexton text-sm pb-4 pb-0 mb-5 uppercase text-white "}>VIA WHATSAPP</p>
                    <div className={"flex flex-col"}>
                        <div>
                            <div className={"download-button"}><a target={"_blank"} href={"https://wa.me/971509135452?text=I've%20been%20invited%20to%20%40" + invitation}><img src={"img/reserve-on-whatsapp.png"} className={"w-100"}/></a></div>
                        </div>
                        <div></div>
                    </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Invites